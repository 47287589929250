import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Input,
  Tag,
  DatePicker,
  Tooltip,
  Avatar,
  Image,
} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import ShowTotal from "../../components/ShowTotal";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import moment from "moment";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import UserFrom from "./UserFrom";
import ImportForm from "../../components/ImportForm";
import ConfirmationBox from "../../components/ConfirmationBox";
import ConfirmationDelete from "../../components/ConfirmationDelete";
import lang from "../../helper/lang";
import { DownloadExcel } from "../../components/ExcelFile";

const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {
  const sectionName = lang("Scanner") + " " + lang("Staff");
  const routeName = "scanner-staff";

  const api = {
    status: apiPath.statusVendorStaff,
    delete: apiPath.deleteVendorStaff,
    addEdit: apiPath.addEditVendorStaff,
    list: apiPath.listVendorStaff,
    importFile: apiPath.importVendorStaff,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const { confirmDelete } = ConfirmationDelete();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [expendedData, setExpandedRowData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();
  console.log(expendedData, "line48");
  const view = (id) => {
    navigate(`/${routeName}/view/${id}`);
  };

  const activity = (id) => {
    navigate(`/user/activity/${id}`);
  };
  useEffect(() => {
    if (list.length > 0 && list[0]._id) {
      setExpandedRowData(list[0]._id);
    }
  }, [list]);

  const columns = [
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { userdata }) => {
        return (
          <>
            <a className="cap avatar-text">{userdata?.name}</a>
          </>
        );
      },
    },
    {
      title: `${lang("Unique_ID")}`,
      dataIndex: "unique_id",
      key: "unique_id",
      render: (_, { userdata }) => {
        return userdata?.unique_id ? userdata?.unique_id : "-";
      },
    },
    {
      title: `${lang("Email")} ${lang("Address")}`,
      dataIndex: "email",
      key: "email",
      width: 220,
      render: (_, { userdata }) => {
        return userdata?.email ? userdata?.email : "-";
      },
    },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      render: (_, { userdata, _id }) => {
        let color = userdata?.is_active ? "green" : "red";
        const type = "is_active";
        return (
          <a>
            <Tag
              onClick={(e) =>
                showConfirm({
                  record: userdata?._id,
                  path: api.status,
                  onLoading: () => setLoading(true),
                  onSuccess: () => setRefresh((prev) => !prev),
                  type,
                })
              }
              color={color}
              key={userdata?.is_active}
            >
              {userdata?.is_active ? "Active" : "Inactive"}
            </Tag>
          </a>
        );
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { userdata }) => {
        return moment(userdata?.created_at).format("DD-MMM-YYYY");
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
  }, [refresh, debouncedSearchText, startDate, endDate]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const filterVerify = filters ? filters.is_verify : null;
    request({
      url:
        api.list +
        `?verify=${filterVerify ? filterVerify.join(",") : ""}&status=${
          filterActive ? filterActive.join(",") : ""
        }&page=${pagination ? pagination.current : 1}&limit=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}&start_date=${
          startDate ? startDate : ""
        }&end_date=${endDate ? endDate : ""}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        const keys = data.data.list.docs.map((data) => {
          data["key"] = data._id;
        });
        console.log(
          data.data.list.docs.filter((user) => user.events.length > 0),
          154444,
        );
        setList(data.data.list.docs.filter((user) => user.events.length > 0));
        setPagination((prev) => ({
          current: pagination.current,
          total: data.data.list.totalDocs,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    console.log("skjnaksckckc>>>>>>>>>>>>>>>");
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStartDate();
      setEndDate();
    }
  };

  const nestedTableColumns = [
    {
      title: `${lang("Event Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id }) => {
        return (
          <a className="cap avatar-text" onClick={(e) => view(_id)}>
            {name ? name : "Not Available"}
          </a>
        );
      },
    },
    {
      title: `${lang("Venue Name")}`,
      dataIndex: "venue_id",
      key: "venue_id",
      render: (_, { venue }) => {
        return (
          <a className="cap avatar-text">
            {venue ? venue?.name : "Not Available"}
          </a>
        );
      },
    },
    {
      title: `Event Type`,
      dataIndex: "event_type",
      key: "event_type",
      render: (_, { event_type, _id }) => {
        return <a className="cap avatar-text">{event_type}</a>;
      },
    },
    {
      title: `${lang("Featured")}`,
      key: "is_featured",

      render: (_, { is_featured, _id }) => {
        let color = is_featured ? "green" : "red";
        const type = "is_featured";
        return (
          <a>
            <Tag color={color} key={is_featured}>
              {is_featured ? "Yes" : "No"}
            </Tag>
          </a>
        );
      },
    },
    {
      title: `Event Date`,
      key: "recurringDate",
      dataIndex: "recurringDate",
      render: (_, { recurringDate }) => {
        return (
          <a className="cap avatar-text">
            {recurringDate ? moment(recurringDate).format("DD-MMM-YYYY") : "-"}
          </a>
        );
      },
    },
    {
      title: `Event Day`,
      key: "recurringDay",
      dataIndex: "recurringDay",
      render: (_, { recurringDay }) => {
        return (
          <a className="cap avatar-text">
            {" "}
            {recurringDay ? recurringDay : "-"}
          </a>
        );
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: `${lang("Action")}`,
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={"Delete Events"}
              color={"purple"}
              key={"update" + routeName}
            >
              <Button
                title="Delete"
                onClick={() =>
                  confirmDelete({
                    record: expendedData,
                    path: api.delete,
                    onLoading: () => setLoading(true),
                    onSuccess: () => setRefresh((prev) => !prev),
                  })
                }
              >
                <i className="fas fa-trash"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const excelData = list.map((row) => ({
    Name: row.userdata.name ? row.userdata.name : "-",
    "Unique Id": row.userdata.unique_id ? row.userdata.unique_id : "-",
    Email: row.userdata?.email ? row.userdata?.email : "-",
    Status: row.userdata?.is_active ? "True" : "False",
    "Registered On": moment(row.created_at).format("DD-MM-YYYY"),
  }));

  return (
    <>
      <div className="tabled specialistMain">
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <div className="searchOuter">
                    <Search
                      allowClear
                      size="large"
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder={`${lang("Search")} ${lang("By")} ${lang(
                        "Name",
                      )}, ${lang("Mobile")}, ${lang("Email")}, ${lang(
                        "Unique_ID",
                      )}`}
                    />
                    <RangePicker
                      disabledDate={(current) => current.isAfter(Date.now())}
                      onChange={handleChangeDate}
                    />
                  </div>
                  <Button
                    title="Export"
                    onClick={(e) => DownloadExcel(excelData, sectionName)}
                  >
                    <i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;
                    {lang("Export")}
                  </Button>
                </>
              }
            >
              <h4 className="text-right mb-1">
                {pagination.total ? ShowTotal(list.length) : ShowTotal(0)}
              </h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  onChange={handleChange}
                  expandable={{
                    expandedRowRender: (record) => (
                      <Table
                        columns={nestedTableColumns}
                        dataSource={record.events}
                        pagination={false}
                      />
                    ),
                    rowExpandable: (record) =>
                      record.events && record.events.length > 0,
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && (
        <UserFrom
          type={sectionName}
          path={api.addEdit}
          sectionName={sectionName}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {importVisible && (
        <ImportForm
          path={api.importFile}
          sectionName={sectionName}
          show={importVisible}
          hide={() => setImportVisible(false)}
          existingData={list}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
}

export default Index;
