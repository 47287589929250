import { Row, Col, Card, Button, Input, Form, Skeleton, Image, Select, Table, Tooltip, Tag, Modal, InputNumber } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import moment from "moment";
import { useNavigate } from "react-router";
import AddForm from "../Event/AddForm";
import notfound from "../../assets/images/not_found.png";
import ConfirmationBox from "../../components/ConfirmationBox";
import ConfirmationDelete from "../../components/ConfirmationDelete";
import lang from "../../helper/lang";

function View() {

  const sectionName = lang("Venue");
  const routeName = "venue";
  const navigate = useNavigate();


  const api = {
    view: apiPath.venueById+"/",
    status: apiPath.eventStatus,

  }

  const params = useParams();
  const [form] = Form.useForm()
  const { request } = useRequest();
  const { confirmDelete } = ConfirmationDelete()

  const [list, setList] = useState();
  const [events, setEvents] = useState([]);
  const [selected, setSelected] = useState();
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false)

  const { showConfirm } = ConfirmationBox()

  const fetchData = (id) => {
    request({
      url: api.view+id ,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  const view = (id) => {
    navigate(`/event/view/${id}`)
  }

  const columns = [
    {
      title: `${lang("Event Image")}`,
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => {
        return <Image className="image-index-radius" src={ image} />
      },
    },
    {
      title: `${lang("Event Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id }) => {
        return (<a className="cap avatar-text" onClick={(e) => view(_id)}>{name ? name : 'Not Available'}</a>);
      }
    },
    {
      title: `${lang("Event Description")}`,
      dataIndex: "description",
      key: "description",
      render: (_, { description, _id }) => {
        return (<a className="cap avatar-text" onClick={(e) => view(_id)}>{description ? description : 'Not Available'}</a>);
      },
    },
     {
      title: `Event Type`,
      dataIndex: "event_type",
      key: "event_type",
      render: (_, { event_type, _id }) => {
        return (<a className="cap avatar-text" >{event_type}</a>);
      },
    },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      // filters: [
      //   {
      //     text: 'Active',
      //     value: true,
      //   },
      //   {
      //     text: 'Inactive',
      //     value: false,
      //   },
      // ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        const type = "is_active";
        return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      }
    },
    {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={`${lang('Update')} ` + sectionName} color={"purple"} key={"update" + routeName}>
            <Button title="Edit" onClick={() => {
                navigate(`/event/edit/${record._id}`)
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
              </Tooltip>
              <Tooltip title={`${lang('Delete')} ` + sectionName} color={"purple"} key={"update" + routeName}>

              <Button title="Delete" onClick={() => confirmDelete({ record: record._id, path: "/vendor/event/", onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>
                <i className="fas fa-trash"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const fetchEvents = () => {
  
    request({
      url: apiPath.venueEvents+params.id,
      method: 'GET',
      onSuccess: ({data}) => {
        setLoading(false)
        console.log(data.data, 5555)
        setEvents(data.data)
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchEvents();
    fetchData(params.id)
  }, [refresh])

  return (
    <>
      <Row gutter={[16, 16]}>

        <Col span={24} xxl={6}>
          
          <Card title={sectionName + ` ${lang('Detail')}`} style={{ height: "100%" }}>
            <Row gutter={16}>
              <Col span={12} xs={24} md={24}>

                {loading ? [1, 2, 3, 4].map(item => <Skeleton active key={item} />) :
                  <div className="view-main-list">

                    <div className="view-inner-cls">
                      <h5>{lang("Image")}:</h5>
                      <h6><Image width={60} src={list?.image ? list?.image : notfound} /></h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>{lang("Name")}:</h5>
                      <h6><span className="cap">{list?.name ? list?.name : '-'}</span></h6>
                    </div>
                    <div className="view-inner-cls">
                      <h5>Bio:</h5>
                      <h6><span className="cap">{list?.bio ? list?.bio : '-'}</span></h6>
                    </div>
        
                    <div className="view-inner-cls">
                      <h5>Detailed description:</h5>
                      <h6><span className="cap">{list?.detailed_description ? list?.detailed_description : '-'}</span></h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Cover Charges:</h5>
                      <h6><span className="cap">{list?.cover_charges ? "$"+list?.cover_charges : 0}</span></h6>
                    </div>
                    
                    <div className="view-inner-cls">
                      <h5>Location:</h5>
                      <h6><span className="cap">{list?.location ? list?.location : 0}</span></h6>
                    </div>
                   
                    <div className="view-inner-cls">
                      <h5>Opening Time:</h5>
                      <h6>{list?.start_time ? list?.start_time: "-"}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Closing Time:</h5>
                      <h6>{list?.end_time ? list?.end_time : "-"}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>{lang("Status")}:</h5>
                      <h6>{list?.is_active ? <Badge status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>{lang("Created_On")}:</h5>
                      <h6>{list?.created_at ? moment(list?.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                    </div>

                    <div className="view-inner-cls float-right">
                      <Link className="ant-btn ant-btn-primary" to={`/${routeName}-manager`}>{lang("Back")}</Link>
                    </div>

                  </div>
                }
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24} xxl={18}>
          
          <Card title={"Scheduled Events"} style={{marginBottom: "10px"}}>
            <Row gutter={16}>
              <Col span={12} xs={24} md={24}>

                {loading ? [1, 2, 3, 4].map(item => <Skeleton active key={item} />) :
                  <div className="view-main-list">
 <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={events}
                  onChange={fetchEvents}
                  className="ant-border-space"
                />
              </div>
                  </div>
                }
              </Col>
            </Row>
          </Card>
          {/* {visible && <AddForm type={sectionName} path={"/vendor/event/edit"} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />} */}

        </Col>
      </Row>
   
    </>
  );
}

export default View;