import { Row, Col, Radio, Modal, Image, Button, Form, Input, Select, InputNumber } from "antd";
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import notfound from "../../assets/images/not_found.png";
import lang from "../../helper/lang";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import useApi from "../../hooks/useApi";
const Option = Select;

const UserFrom = ({ type, path, sectionName, show, hide, data, selected, refresh }) => {
  const api = {
    // userList: apiPath.allUserList,
    eventList: apiPath.eventByVenueId,
    editTable: apiPath.editEvent,
    addEditTable: apiPath.addEditTable,
    venueList: apiPath.venueList
  }

  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([])
  const { getCountry } = useApi();


  const [venues, setVenues] = useState([]);
  const [events,setEvents]=useState([])

  const getVenues = () => {
    request({
      url: api.venueList,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
        }
        setVenues(data.data.docs);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }

  const getEvents = (id) => {
    request({
      url: api.eventList+"/"+id,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
        }
        setEvents(data.data.data.filter((event) => event.tableData));
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }


  const handleVenueChange = (data) => {
    form.setFieldValue("event_id",undefined)
    form.setFieldValue("venue_id", data);
    getEvents(data);
  }

  useEffect(() => {
    getVenues();
    if (!data) return;
    getEvents(data?.venue_id?._id)
    form.setFieldsValue({
      ...data,
      country: data.country_id,
      venue_id: data?.venue_id?._id,
      event_id: data?.event_id?._id

    });
   
  }, [data, form, countries])


  const onCreate = (values) => {
    setLoading(true);
   
    const payload = {
      ...values,
      capicity: values.max_male , 
    };
    if(selected){
      payload["venue_id"] = selected.venue_id
      payload["event_id"] = selected.event_id
    }
    
    if(data){
      
      payload["table_id"]=data._id;
    }
    request({              

      url: api.addEditTable,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    })
  };




  return (
    <Modal
      width={800}
      open={show}
      title={`${data ? lang("Update") + " " + sectionName : lang("Add") + " " + lang("New") + " " + sectionName}`}
      okText={`${data ? lang("Update") : lang("Add")}`}
      cancelText={
        <>
          <CloseOutlined style={{ marginRight: '8px' }} />
          {lang("Cancel")}
        </>
      }
      onCancel={hide}
      okButtonProps={{
        form: 'formCreate',
        htmlType: 'submit',
        loading: loading,
        icon: < CheckOutlined />,
      }}
    >
      <Form id="formCreate" form={form} initialValues={{}} onFinish={onCreate} layout="vertical">
        <Row gutter={[24, 16]}>
          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Table Name"
              name="name"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the table name!" },
                { max: 200, message: "Event Name should not contain more then 200 characters!" },
                { min: 2, message: "Event Name should contain at least 2 characters!" },
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Table Name" />
            </Form.Item>
          </Col>
              {console.log(selected, 'selected>>>>>>.')}
         { !data && !selected?<><Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Venue Name"
              name="venue_id"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select venue!" },
              ]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                placeholder="Select Venue"
                onChange={handleVenueChange}
                options={venues?.map(v => {
                  return { label: v?.name, value: v?._id}
                })}             

              // options={[{ value: "India", label: "India" }, { value: "Canada", label: "Canada" }, { value: "USA", label: "USA" }]}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Event Name"
              name="event_id"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select Event!" },
              ]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                placeholder="Select Event"
                onChange={(value) => form.setFieldValue("event_id", value)}
                options={events?.map(v => {
                  return { label: v?.name, value: v?._id }
                })}
              // options={[{ value: "India", label: "India" }, { value: "Canada", label: "Canada" }, { value: "USA", label: "USA" }]}
              />
            </Form.Item>
          </Col></>
          : ""}

          <Col span={24} sm={12}>
            <Form.Item
              label="Min Cost($)"
              name="min_cost"
              rules={[
                { required: true, message: "Missing min_cost value" },
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={10} autoComplete="off" placeholder="Enter minimum cost" />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item

              label="Max people"
              name="max_male"
              rules={[
                { required: true, message: "Missing" },
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={10} autoComplete="off" placeholder="Enter numbers" />
            </Form.Item>
          </Col>


          {/* <Col span={24} sm={12}>
            <Form.Item
              label="Table Capicity"
              name="capicity"
              rules={[
                { required: true, message: "Missing Table Capicity value" },
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={10} autoComplete="off" placeholder="Enter Table Capicity" />
            </Form.Item>
          </Col> */}
          <Col span={24} sm={12}>
            <Form.Item

              label="Reservation fee($)"
              name="reservation_fee"
            >
              <Input maxLength={10} autoComplete="off" placeholder="Enter Reservation fee" />
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal >
  )
};

export default UserFrom;