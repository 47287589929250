
import { useState, useEffect, useContext } from "react";
import { Row, message, Col, Breadcrumb, Select, Dropdown, Button, Card, Input, Image, Form, Modal, Upload, TextArea } from "antd";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from "../../context/AuthContext";
import { KeyOutlined, LogoutOutlined, UserOutlined, SettingOutlined, QuestionCircleFilled } from "@ant-design/icons";
import apiPath from "../../constants/apiPath";
import { ShowToast, Severty } from "../../helper/toast";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import notfound from "../../assets/images/not_found.png";
import RecentNotificationItem from "../RecentNotificationItem";
import notification from "../../assets/images/icon/notification.svg";
import useNotification from "../../hooks/useNotification";
import lang from "../../helper/lang";
import SingleImageUpload from "../SingleImageUpload";
import defaultLogo from "../../assets/images/default-logo.png"

const { confirm } = Modal;

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];


const languages = [
  {
    label: "English (En)",
    value: "en",
    icon: "\uD83C\uDDEC\uD83C\uDDE7" // English flag icon
  },
  {
    label: "العربية (Ar)",
    value: "ar",
    icon: "\uD83C\uDDE6\uD83C\uDDEB", // Arabic flag icon
  },
];


function Header({ name, onPress }) {

  useEffect(() => window.scrollTo(0, 0));
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false);
  
  const {recentActivityNotifications, fetchRecentActivityNotifications, deleteNotification, readNotification} = useNotification();

  const { language, setLanguage } = useAppContext();

  const [profile, setProfile] = useState({});
  const [selected, setSelected] = useState();
  const [profileVisible, setProfileVisible] = useState(false);

  const [appSetting, setAppSetting] = useState({});
  const [breadcrumb, setBreadcrumb] = useState(name?.split('/') ?? []);
  const [appSettingSelected, setAppSettingSelected] = useState();
  const [appSettingVisible, setAppSettingVisible] = useState(false);

  const { request } = useRequest()
  const [refresh, setRefresh] = useState(false)
  const { logout } = useContext(AuthContext)
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const items = [
    {
      label: lang("Update") + " " + lang("Profile"),
      key: '1',
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: lang("Change") + " " + lang("Password"),
      key: '2',
      icon: <KeyOutlined />,
      danger: true,
    },
    {
      label: lang("App") + " " + lang("Setting"),
      key: '4',
      icon: <SettingOutlined />,
      danger: true,
    },
    {
      label: lang("Logout"),
      key: '3',
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];

  const showDeleteConfirm = (record) => {
    setTimeout(() => {
      confirm({
        okText: lang("Logout"),
        okType: 'danger',
        icon: <QuestionCircleFilled />,
        cancelText: lang("Cancel"),
        content: <Button>{lang("Are_you_sure_you_want_to")} {lang("Logout")} ?</Button>,
        onOk() {
          logout();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const notificationItems = [
    {
      label: (
        <div className="notification_top">
          <div className="notification-head">
            <h5> {lang("Notification")} </h5>
            {recentActivityNotifications &&
            recentActivityNotifications.length > 0 ? (
              <>
                <a onClick={(e) => deleteNotification()}> {lang("Delete")} {lang("All")} </a>
                <a onClick={(e) => readNotification()}> {"Read All "} </a>
                <a onClick={(e) => navigate("/view-notification")}> {"View All "} </a>

              </>
            ) : null}
          </div>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="notification-main-wrap notificationDrop notificationScroll">
          {recentActivityNotifications && recentActivityNotifications.length > 0
            ? recentActivityNotifications.map((item, index) => (
                <RecentNotificationItem
                  item={item}
                  deleteNotification={deleteNotification}
                  readNotification={readNotification}
                  key={item._id + index}
                />
              ))
            : `${lang("No")} ${lang("Notification")} ${lang("Found")}`}
        </div>
      ),
      key: "1",
    }
  ];

  const handleMenuClick = (e) => {
    if (e.key == 2) {
      setVisible(true)
    }
    if (e.key == 1) {
      setProfileVisible(true)
    }
    if (e.key == 4) {
      setAppSettingVisible(true)
    }
    if (e.key == 3) {
      showDeleteConfirm()
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onCreate = (values) => {
    const { old_password, new_password } = values
    const payload = {}
    if (!old_password || !new_password) return ShowToast('Please enter password ', Severty.ERROR)
    setLoading(true)
    payload.new_password = new_password
    payload.old_password = old_password
    request({
      url: apiPath.changePassword,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          setVisible(false)
          logout()
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  useEffect(() => {
    const splitName = name?.split('/');
    setBreadcrumb(splitName);
}, [name]);

  useEffect(() => {
    request({
      url: apiPath.profile,
      method: 'GET',
      onSuccess: (data) => {
        setProfile(data.data)
        setSelected(data.data)
      },
    })
    request({
      url: apiPath.getAppSetting,
      method: 'GET',
      onSuccess: (data) => {
        setAppSetting(data.data)
        setAppSettingSelected(data.data)
      },
    })
    fetchRecentActivityNotifications();
  }, [refresh])

  return (
    <>

      <Row gutter={[24, 0]}>
        <Col span={24} xs={18} md={18} className="breadcrumbMain">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Home</NavLink>
            </Breadcrumb.Item>

            {
              breadcrumb.map((item, index) =>
                <Breadcrumb.Item key={item} style={{ textTransform: "capitalize" }}>
                  {/* <NavLink to="/">{item}</NavLink> */}
                  {item}
                </Breadcrumb.Item>
              )
            }


          </Breadcrumb>

        </Col>

        <Col span={24} xs={24} sm={6} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          

          <div className="profileDropdownMain">
            <Dropdown menu={menuProps}>
              <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open">
                <div className="userName">{profile ? profile.name : "Administrator"}</div>
                <div className="userEmail">{profile ? profile.email : "admin@noreply.com"} </div>
              </Button>
            </Dropdown>
            <Image src={profile && profile.image ? profile.image : defaultLogo} preview={false} />
          </div>

          <div className="notificationDropdownMain">
            <Dropdown
              menu={{
                items: notificationItems,
              }}
              trigger={["click"]}
            >
              <button
                // onClick={(e) => e.preventDefault()}
                className="ant-dropdown-trigger ant-dropdown-open notificationBtn"
              >
                <img src={notification} />
                <div className="notiFicationBadge">
                  {!!recentActivityNotifications && recentActivityNotifications.length > 0 ? recentActivityNotifications.length : 0}
                </div>
                
              </button>
            </Dropdown>
          </div>
          
          {/* <div className="country-wrap">
            <Select
              value={language}
              options={languages}
              onChange={(value) => {
                localStorage.setItem("languageSet", value);
                window.location.reload();
              }}
            />
          </div> */}
          
        </Col>
      </Row>

      {profileVisible && <EditProfile show={profileVisible} hide={() => { setProfileVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      {appSettingVisible && <AppSetting show={appSettingVisible} hide={() => { setAppSettingVisible(false) }} data={appSettingSelected} refresh={() => setRefresh(prev => !prev)} />}

      <Modal
        open={visible}
        title={`${lang("Change")} ${lang("Password")}`}
        okText={`${lang("Update")}`}
        cancelText={`${lang("Cancel")}`}
        onCancel={() => {
          setVisible(false);
        }}
        okButtonProps={{
          form: 'create',
          htmlType: 'submit',
          loading: loading,
        }}
        onOk={handleCreate}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Old Password"
            name="old_password"
            hasFeedback
            rules={[
              { required: true, message: "Please enter the old password!" },
            ]}
          >
            <Input.Password placeholder="Enter your old password" />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            dependencies={['old_password']}
            hasFeedback
            rules={[
              { required: true, message: "Please enter the new password!" },
              { min: 6, message: "Please enter the atleast 6 charcaters!" },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('old_password') === value) {
                    return Promise.reject(new Error('Old password & new password must be different!'));
                  }
                  return Promise.resolve();

                },
              }),
            ]}
          >
            <Input.Password placeholder="Enter your new password" />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirm_new_password"
            dependencies={['new_password']}
            hasFeedback
            rules={[
              { required: true, message: "Please enter the confirm password!" },
              { min: 6, message: "Please enter the atleast 6 charcaters!" },
              // { pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/), message: "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character" },
             
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Confirm password & password does not match!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Enter your confirm password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const EditProfile = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]

  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data })
    setFile([data.image]);
  }, [data])

  const handleImage = (data) => {
    setImage(data);
    data.length > 0 ? setFile(data[0].url) : setFile([]);
  };

  const onEditProfile = (values) => {
    const { email, name } = values
    const payload = {}
    setLoading(true)
    payload.email = email
    payload.name = name
    payload.image = image && image.length > 0 ? image[0].url : ""
    
    request({
      url: apiPath.updateProfile,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      open={show}
      title={`${data ? lang("Update") + " " + lang("Profile") : ''}`}
      okText={`${lang("Update")}`}
      cancelText={`${lang("Cancel")}`}
      width={750}
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onEditProfile} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Name" name="name"
              rules={[
                { required: true, message: "Please enter the name!" },
                { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "Only Alphabetic Characters Allowed" }
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email Address" name="email"
              rules={
                [
                  {
                    type: 'email', message: 'The input is not valid E-mail!',
                  },
                  { required: true, message: "Please enter the email!" }
                ]}
            >
              <Input placeholder="Enter Email Address" disabled />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              className="mb-0 main-imageuplod"
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Image is required"));
                  },
                },
              ]}
              label={"Upload Profile"}
              name="image"
            >
              <SingleImageUpload className="main-imageuplod" value={image} fileType={FileType} imageType={"user"} btnName={"Profile"} onChange={(data) => handleImage(data)} />
            </Form.Item>

            {file && file.length > 0 && (
              <div className="mt-2"> <Image width={120} src={file !== "" ? file : notfound} >
                </Image>
                {/* <Button title="Delete" onClick={()=> setFile([])}><i className="fas fa-trash"></i></Button> */}

                 </div>
            )}

          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export const AppSetting = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data });
  }, [data])


  const onAppSetting = (values) => {
    setLoading(true)
    request({
      url: apiPath.updateAppSetting,
      method: 'POST',
      data: values,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      width={1200}
      open={show}
      title={`${data ? lang("Update") + " " + lang("App") + " " + lang("Setting") : ''}`}
      okText={`${lang("Update")}`}
      cancelText={`${lang("Cancel")}`}
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >

      <Form id="create" form={form} onFinish={onAppSetting} layout="vertical">
        <Row gutter={{ xs: [0, 16], md: [16, 0] }}>

          <Col span={24} md={12}>
            <Card title="Android Details">
              <Col span={24}>
                <Form.Item label="App Store URL" name="app_store_url"
                  rules={[
                    { required: true, message: "Please enter the app store URL!" },
                  ]}
                >
                  <Input placeholder="Enter App Store URL" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Version" name="android_version"
                  rules={[
                    { required: true, message: "Please enter the version!" },
                  ]}
                >
                  <Input placeholder="Enter Android Version" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Share Content" name="android_share_content"
                  rules={[
                    { required: true, message: "Please enter the share content!" },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Share Android Content"
                  />

                </Form.Item>
              </Col>
            </Card>
          </Col>


          <Col span={24} md={12}>
            <Card title="IOS Details">

              <Col span={24} className="">
                <Form.Item label="Play Store URL" name="play_store_url"
                  rules={[
                    { required: true, message: "Please enter the play store URL!" },
                  ]}
                >
                  <Input placeholder="Enter Play Store URL" />
                </Form.Item>
              </Col>

              <Col span={24} className="">
                <Form.Item label="Version" name="ios_version"
                  rules={[
                    { required: true, message: "Please enter the version!" },
                  ]}
                >
                  <Input placeholder="Enter IOS Version" />
                </Form.Item>
              </Col>

              <Col span={24} className="">
                <Form.Item label="Share Content" name="ios_share_content"
                  rules={[
                    { required: true, message: "Please enter the share content!" },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Share IOS Content"
                  />
                </Form.Item>
              </Col>
            </Card>
          </Col>

        </Row>

      </Form>
    </Modal>
  )
}

export default Header;
