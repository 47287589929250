import { BrowserRouter, Routes, Route, Outlet , Navigate} from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { AppContextProvider } from './context/AppContext';
import React, { useContext, Suspense } from 'react';
import Home from "./pages/Auth/Home";
import Profile from "./pages/Auth/Profile";
import SignIn from "./pages/Auth/SignIn";
import PrivateRoute from './components/PrivateRoute'
import ScrollToTop from './components/ScrollToTop';
import Loader from './components/Loader';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Error from './pages/Error';
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";

import Customer from './pages/User/Customer/Index';
import CustomerView from './pages/User/Customer/View';
import Activity from './pages/User/Activity';

import VenueManager from './pages/VenueManager/Index';
import VenueManagerView from './pages/VenueManager/View';
import VenueAdd from './pages/VenueManager/Add';
import VenueEdit from './pages/VenueManager/Edit';

import PhotosManager from './pages/Photos/Index';
import PhotosManagerView from './pages/Photos/View';

import Guestlist from './pages/GuestList/Index';
import GuestlistView from './pages/GuestList/View';

import EventManager from './pages/Event/Index';
import EventManagerView from './pages/Event/View';
import AddEventManager from './pages/Event/AddForm';
import EditEventManager from './pages/Event/AddForm';

import TableManager from './pages/Table/Index';
import TableManagerView from './pages/Table/View';

import Vendor from './pages/User/Vendor/Index';
import VendorView from './pages/User/Vendor/View';

import VendorStaff from './pages/User/VendorStaff/Index';
import VendorStaffView from './pages/User/VendorStaff/View';

import ScannerStaff from './pages/ScannerStaff/Index';
import ScannerStaffView from './pages/ScannerStaff/View';

// import TableReservationPayment from './pages/AccessPayment/TableReservation/Index';
// import TableReservationPaymentView from './pages/AccessPayment/TableReservation/View';

import TicketBuySummary from './pages/TicketSummary/Index';
// import TicketBuySummaryView from './pages/AccessPayment/TicketSummary/View';

import Content from './pages/Content/Index';
import ContentView from './pages/Content/View';
import ContentEdit from './pages/Content/Edit';

import Transaction from './pages/Transaction/Index';
import TransactionView from './pages/Transaction/View';
import Notification from './pages/Notification/Index';


window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {

  return (
    <AuthProvider>
      <AppContextProvider>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <ScrollToTop />
              <ToastContainer closeOnClick={false} />
              <AppRoutes />
            </BrowserRouter>
          </Suspense>
        </I18nextProvider>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {

  const { isLoggedIn } = useContext(AuthContext)

  return (
    <Routes >
      <Route path="/login"  element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <SignIn />} />
      {/* <Route path="/" element={<SignIn />} /> */}

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>}
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />

        {/* Vendor Routes */}
        <Route exact path="/user/vendor" element={<Vendor />} />
        <Route exact path="/user/vendor/view/:id" element={<VendorView />} />     

        {/* Customer Routes */}
        <Route exact path="/user/customer" element={<Customer />} />
        <Route exact path="/user/customer/view/:id" element={<CustomerView />} />
        <Route exact path="/user/activity/:id" element={<Activity />} />

        {/* Vendor Staff Routes */}
        <Route exact path="/user/vendor-staff" element={<VendorStaff />} />
        <Route exact path="/user/vendor-staff/view/:id" element={<VendorStaffView />} />

        {/* Transaction Routes */}
        <Route exact path="/transaction" element={<Transaction />} />
        <Route exact path="/transaction/view/:id" element={<TransactionView />} />


       

        {/* Venue Manger Routes */}
        <Route exact path="/venue-manager" element={<VenueManager />} />
        <Route exact path="/venue-manager/view/:id" element={<VenueManagerView />} />
        <Route exact path="/venue-manager/add" element={<VenueAdd />} />
        <Route exact path="/venue-manager/update/:id" element={<VenueEdit />} />

        {/* Photos Manger Routes */}
        <Route exact path="/photos" element={<PhotosManager />} />
        <Route exact path="/photos/view/:id" element={<PhotosManagerView />} />

        {/* Event Manger Routes */}
        <Route exact path="/event" element={<EventManager />} />
        <Route exact path="/event/view/:id" element={<EventManagerView />} />
        <Route exact path="/event/edit/:id" element={<AddEventManager />} />
        <Route exact path="/event/add" element={<AddEventManager />} />

        {/* Table Manger Routes */}
        <Route exact path="/table" element={<TableManager />} />
        <Route exact path="/table/view/:id" element={<TableManagerView />} />

        {/* Table Manger Routes */}
        <Route exact path="/guest-list" element={<Guestlist />} />
        <Route exact path="/guest-list/view/:id" element={<GuestlistView />} />

        <Route exact path="/view-notification" element={<Notification />} />

         {/* scannerStaff Manger Routes */}
         <Route exact path="/scanner-staff" element={<ScannerStaff />} />
        <Route exact path="/scanner-staff/view/:id" element={<ScannerStaffView />} />

        {/* Access Payments Routes */}
        {/* <Route exact path="/payment/tables" element={<TableReservationPayment />} />
        <Route exact path="/payment/tables/view/:id" element={<TableReservationPaymentView />} /> */}

         {/* Access Payments Routes */}
         <Route exact path="/payment/tickets" element={<TicketBuySummary />} />
        {/* <Route exact path="/payment/tables/view/:id" element={<TicketBuySummaryView />} /> */}

        {/* Content Routes */}
        <Route exact path="/content" element={<Content />} />
        <Route exact path="/content/update/:slug?" element={<ContentEdit />} />
        <Route exact path="/content/view/:slug" element={<ContentView />} />

      </Route >

      <Route path="*" element={<Error />} />
    </Routes >
  )
}

const Layout = () => {
  return (<> <Outlet />  </>
  )
}

// function App() {
//   const { isLoggedIn } = useContext(AuthContext)
//   return (
//     <div className="App">
//       <BrowserRouter>
//         <Routes >
//           <Route path="/" element={(isLoggedIn) ? <Home /> : <Home />} />

//           <Route
//             path="/"
//             element={
//               <Layout />
//             }
//           >

//           </Route>
//           <Route exact path="/dashboard" element={<Home />} />
//           <Route exact path="/tables" element={<Tables />} />

//           {/* <Route exact path="/profile" component={Profile} /> */}
//           {/* <Redirect from="*" to="/dashboard" /> */}

//         </Routes>
//       </BrowserRouter>
//       {/* <Switch>

//       </Switch> */}
//     </div>
//   );
// }

// const Layout = () => {
//   return (
//     <Main> <Outlet />  </Main>
//   )
// }

export default App;