import { Row, Col, Card, Table, Button,Select, Input, Tag, Tooltip, DatePicker, Space } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { PRICE, PAYMENT_TYPE, USERTYPE } from "../../constants/conts";
import * as XLSX from 'xlsx';
import ShowTotal from "../../components/ShowTotal";
import moment from 'moment';
import lang from "../../helper/lang";
const { RangePicker } = DatePicker;
const Search = Input.Search;

function Index() {

  const sectionName = lang("Transaction");

  const api = {
    userList  : apiPath.allUserList,
    list      : apiPath.listTransaction,
  }

  const [searchText, setSearchText]   =   useState('');
  const navigate                      =   useNavigate();
  const { request }                   =   useRequest()
  const [list, setList]               =   useState([])
  const [refresh, setRefresh]         =   useState(false)
  const [loading, setLoading]         =   useState(false)
  const [startDate, setStartDate]     =   useState();
  const [endDate, setEndDate]         =   useState();
  const [pagination, setPagination]   =   useState({ current: 1, pageSize: 10 });
  const debouncedSearchText           =   useDebounce(searchText, 300);

  const [vendorList, setVendorList]   =   useState([]);
  const [driverList, setDriverList]   =   useState([]);
  const [vendorId, setVendorId]       =   useState();
  const [driverId, setDriverId]       =   useState();
  const [paymentTypeId, setPaymentTypeId]       =   useState();


  const getVendorList = () => {
    request({
      url: api.userList,
      method: 'POST',
      data: { type : USERTYPE.VENDOR },
      onSuccess: (data) => {
        setVendorList(data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const getDriverList = () => {
    request({
      url: api.userList,
      method: 'POST',
      data: { type : USERTYPE.DRIVER },
      onSuccess: (data) => {
        setDriverList(data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const view = (record) => {
    navigate(`/transaction/view/${record._id}`)
  }

  const columns = [
    {
      title: `${lang("Transaction")} ${lang("ID")}`,
      dataIndex: "transaction_id",
      key: "transaction_id",
      sorter: (a, b) => {
        let nameA = a.email;
        let nameB = b.email;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: `${lang("Transaction")} ${lang("Type")}`,
      dataIndex: "type",
      filters: [
        {
          text: 'OnDemand',
          value: 'OnDemand',
        },
        {
          text: 'Subscription',
          value: 'Subscription',
        },
      ],

      render: (_, { type, subscription_id }) => {
        if (type === 'OnDemand') {
          return <span className="cap">{type}</span>;
        } else if (type === 'Subscription') {
          if (subscription_id && subscription_id.name) {
            return <span className="cap">{type} - {subscription_id.name}</span>;
          } else {
            return <span className="cap">{type}</span>;
          }
        } else {
          return '-';
        }
      },
    },
    {
      title: `${lang("Customer")} ${lang("Detail")}`,
      dataIndex: "customer_id",
      key: "customer_id",
      width: 210,
      render: ( customer_id ) => {
        const { name, email, country_code, mobile_number } = customer_id || {};
        const countryCode = country_code ? `+${country_code}-` : '+968';
        const mobileNumber = mobile_number || '';
        
        return customer_id ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Email : {email}</div>
            <div>Mobile : {countryCode}{mobileNumber}</div>
          </div>
        ) : '-';
      }
    },
    {
      title: `${lang("Vendor")} ${lang("Detail")}`,
      dataIndex: "vendor_id",
      key: "vendor_id",
      width: 200,
      render: ( vendor_id ) => {
        const { name, email, country_code, mobile_number } = vendor_id || {};
        const countryCode = country_code ? `+${country_code}-` : '+968';
        const mobileNumber = mobile_number || '';
        
        return vendor_id ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Email : {email}</div>
            <div>Mobile : {countryCode}{mobileNumber}</div>
          </div>
        ) : '-';
      }
    },
    {
      title: `${lang("Driver")} ${lang("Detail")}`,
      dataIndex: "driver_id",
      width: 200,
      key: "driver_id",
      render: ( driver_id ) => {
        const { name, email, country_code, mobile_number } = driver_id || {};
        const countryCode = country_code ? `+${country_code}-` : '+968';
        const mobileNumber = mobile_number || '';
        
        return driver_id ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Email : {email}</div>
            <div>Mobile : {countryCode}{mobileNumber}</div>
          </div>
        ) : '-';
      }
    },
    {
      title: `${lang("Admin")} ${lang("Earning")} ${lang("Balance")} (${PRICE})`,
      dataIndex: "admin_earning_balance",
      key: "admin_earning_balance",
      render: (_, { admin_earning_balance }) => {
        return (
          admin_earning_balance ? admin_earning_balance : 0.00
        )
      },
    },
    {
      title: `${lang("Total")} ${lang("Amount")} (${PRICE})`,
      dataIndex: "total",
      key: "total",
      render: (_, { total }) => {
        return total ? total : '-';
      },
    },
    {
      title: `${lang("Payment")} ${lang("Mode")}`,
      dataIndex: "payment_type",
      key: "payment_type",
      render: (_, { payment_type }) => {
        return payment_type ? <span className="cap">{payment_type}</span> : '-';
      },
    },
    {
      title: `${lang("Payment")} ${lang("Status")}`,
      key: "payment_status",
      filters: [
        {
          text: 'Cod',
          value: 'Cod',
        },
        {
          text: 'Success',
          value: 'success',
        },
        {
          text: 'Cancel',
          value: 'cancel',
        },
        {
          text: 'Pending',
          value: 'pending',
        },
      ],
      render: (_, { payment_status }) => {
        let color = payment_status === 'success' ? 'green' : payment_status === 'cancel' ? 'red' : 'yellow';
        return (
          <Tag className="cap" color={color} key={payment_status}> {payment_status ? payment_status : 'Pending'} </Tag>
        );
      },
    },
    {
      title: `${lang("Transaction_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format('DD-MM-YYYY');
      },
    },
    {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={`${lang("View")} ` + sectionName} color={"purple"} key={"viewtransaction"}>
              <Button title="View" onClick={(e) => view(record)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
    getVendorList()
    getDriverList()
  }, [refresh, debouncedSearchText, startDate, endDate, vendorId, driverId, paymentTypeId])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.payment_status : null;
    const filterType   = filters ? filters.type : null;
    const payload = {}
    payload.payment_status = filterActive ? filterActive.join(',') : '';
    payload.type = filterType ? filterType.join(',') : '';
    payload.page = pagination ? pagination.current : 1;
    payload.limit = pagination ? pagination.pageSize : 10;
    payload.search = debouncedSearchText;
    payload.start_date = startDate ? startDate : "";
    payload.end_date = endDate ? endDate : "";
    payload.vendor_id = vendorId ? vendorId : "";
    payload.driver_id = driverId ? driverId : "";
    payload.payment_type = paymentTypeId ? paymentTypeId : "";

    request({
      url: api.list,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleReset = () => {
    setDriverId();
    setVendorId();
    setPaymentTypeId();
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleVendor = (e) => {
    setVendorId(e)
    setPagination({ current: 1 })
  };

  const handlePaymentType = (e) => {
    setPaymentTypeId(e)
    setPagination({ current: 1 })
  };

  const handleDriver = (e) => {
    setDriverId(e)
    setPagination({ current: 1 })
  };

  const handleChangeDate = (e) => {
    if(e!=null){
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    }else{
      setStartDate()
      setEndDate()
    }
  };

  const handleExport = () => {
    const data = list.map(row => ({
      "Transaction ID": row.transaction_id,
      "Payment Status": row.order_id && row.order_id.payment_status ? row.order_id.payment_status : '-',
      "Payment Mode": row.order_id && row.order_id.payment_type ? row.order_id.payment_type : '-',
      "Transaction Type": row.transaction_type,
      "Customer Name": row.customer_id && row.customer_id.name ? row.customer_id.name : '-',
      "Driver Name": row.driver_id && row.driver_id.name ? row.driver_id.name : '-',
      "Vendor Name": row.vendor_id && row.vendor_id.name ? row.vendor_id.name : '-',
      "Payment Status": row.payment_status,
      "Total Amount (OMR)": row.total,
      "Transaction On": moment(row.created_at).format("DD-MM-YYYY"),
    }));

    const workbook  = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transaction Data');
    XLSX.writeFile(workbook, `${moment().milliseconds() + 1000 * (moment().seconds() + 60 * 60)+ "-transactions"}.xlsx`);
  };

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={sectionName + ` ${lang("Manager")}`}
            extra={
              <>
                <Search size="large" onChange={onSearch} onPressEnter={onSearch} value={searchText} placeholder={`${lang("Search")} ${lang("By")} ${lang("Transaction")} ${lang("ID")}`} />
                <RangePicker onChange={handleChangeDate} />
                <Button title="Export" onClick={handleExport}><i className="fas fa-download"></i>&nbsp;&nbsp;{lang("Export")}</Button>
              </>
            }
          >

            <Row gutter={[24,0]} >
              
              <Col xs={24} xl={8} sm={8} md={8} lg={8} className="mb-24">
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  value={paymentTypeId}
                  placeholder={lang("Filter_By") + ' ' + lang("Payment") + ' ' + lang("Mode")}
                  name="payment_type"
                  onChange={handlePaymentType}
                  filterOption={(input, option) =>
                    (option.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {PAYMENT_TYPE && PAYMENT_TYPE.map((item) => (
                    <Select.Option key={item.id} value={item.id} label={item.value}>
                      <span className="cap">{item.value}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              <Col xs={24} xl={8} sm={8} md={8} lg={8} className="mb-24">
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  value={vendorId}
                  placeholder={lang("Filter_By") + ' ' + lang("Vendor")}
                  name="vendor_id"
                  onChange={handleVendor}
                  filterOption={(input, option) =>
                    (option.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {vendorList && vendorList.length > 0 && vendorList.map((item) => (
                    <Select.Option key={item._id} value={item._id} label={item.name}>
                      <span className="cap">{item.name}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              <Col xs={24} xl={8} sm={8} md={8} lg={8} className="mb-24" style={{display:"flex", alignItems:"center", gap:"6px"}}>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  value={driverId}
                  placeholder={lang("Filter_By") + ' ' + lang("Driver")}
                  name="driver_id"
                  onChange={handleDriver}
                  filterOption={(input, option) =>
                    (option.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {driverList && driverList.length > 0 && driverList.map((item) => (
                    <Select.Option key={item._id} value={item._id} label={item.name}>
                      <span className="cap">{item.name}</span>
                    </Select.Option>
                  ))}
                </Select>

                <Button style={{ width: 'auto' }} onClick={handleReset} title="Refresh">
                  <i className="fas fa-sync-alt"></i>
                </Button>

              </Col>
              
            </Row>

            <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
            <div className="table-responsive customPagination">
              <Table
                loading={loading}
                columns={columns}
                dataSource={list}
                pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                onChange={handleChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Index;