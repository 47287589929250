export const PRICE = "OMR";

export const FIREBASECONFIG = {
    apiKey: "AIzaSyBi34M-2_i-5ZXL952mqenH1ytFj8c-43Y",
    authDomain: "subscart-1304.firebaseapp.com",
    projectId: "subscart-1304",
    storageBucket: "subscart-1304.appspot.com",
    messagingSenderId: "698583720914",
    appId: "1:698583720914:web:463c9ad9dc9f03c9019e0b",
    measurementId: "G-377SWDFRVQ"
};

export const OTPMessage = "Enter OTP that has been sent to your mobile number.";

export const bannerType = [
    {id : 'normal', value : "Normal"},
    {id : 'category', value : "Category"},
]

export const PAYMENT_TYPE = [
    {id : 'cod', value : "COD"},
    {id : 'online', value : "Online"},
    // {id : 'card', value : "Card"},
    // {id : 'upi', value : "UPI"},
]

export const ORDER_STATUS = {
    pending             :   "pending",
    confirmed           :   "confirmed",
    packaged            :   "packaged",
    out_for_delivery    :   "out_for_delivery",
    on_the_way          :   "on_the_way",
    delivered           :   "delivered",
    cancelled           :   "cancelled",
};

export const MAXPRODUCTLIMITTYPE = [
    {value : "limited", name : "Limited"},
    {value : "unlimited", name : "Unlimited"}
];

export const USERTYPE = {
    VENDOR : 'Vendor',
    VENDOR_STAFF : 'VendorStaff',
    DRIVER : 'Driver',
    CUSTOMER : 'Customer',
    ADMIN : 'Admin',
    SUB_ADMIN : 'SubAdmin',
};

export const DRIVER_TYPE = [
    {value : "payroll", name : "Payroll"},
    {value : "third_party", name : "3rd Party"}
];

export const SUBSCRIPTIONOFFEREDTYPE = [
    {value : "client_location", name : "Client Location"},
    {value : "vendor_location", name : "Vendor Location"},
    {value : "both", name : "Both"}
];