import React, { useState, useEffect } from "react";
import { Card, Col, Row, Form,Typography, Radio, Select, Table, DatePicker,Button, Image,Tooltip, Badge } from "antd";
import { Link } from 'react-router-dom';
import { PRICE } from "../../constants/conts";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import { ShowToast, Severty } from "../../helper/toast";
import moment from 'moment';
import apiPath from "../../constants/apiPath";
import Logo from "../../assets/images/Logo.png";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from "chart.js";
import { Bar } from "react-chartjs-2";
import lang from "../../helper/lang";
const {Option} = Select;
const { RangePicker } = DatePicker;

function Home() {

  const { Title, Text } = Typography;
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([]);
  const [recentOrder, setRecentOrder] = useState([]);
  const [earning, setEarning] = useState([]);
  const [events, setEvents] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const navigate = useNavigate();

  ChartJS.register(CategoryScale, LinearScale, BarElement);

  const [dashboard, setDashboard] = useState({
    customerCount: 0,
    vendorCount: 0,
    totalVenues: 0,
    totalEvents: 0,
    last10Transaction: [],
    last10Guests: [],
  })
  const [form] = Form.useForm();

  const options = {
    indexAxis: 'y',
  }
  const getEvents = () => {
    request({
      url: apiPath.eventListing,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
          console.log(data.data, 3222)
        }
        setEvents(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }
  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    } else {
      setStartDate()
      setEndDate()
    }
  };

 
  const earningsColumn = [
    {
      title: "Earnings (" + PRICE + ")",
      value: `${earning && earning.balance ? earning.balance : 0}`,
      icon: <i className="fas fa-money-bill"></i>,
    },
    {
      title: "Total Balance (" + PRICE + ")",
      value: `${
        earning && earning.total_balance ? earning.total_balance : 0
      }`,
      icon: <i className="fas fa-balance-scale"></i>,
    },
    {
      title: "Revenue (" + PRICE + ")",
      value: `${earning && earning.revenue ? earning.revenue : 0}`,
      icon: <i className="fas fa-chart-bar"></i>,
    },
  ];

  const count = [
    {
      today: `My Venues`,
      title: `${dashboard && dashboard.totalVenues ? dashboard.totalVenues : 0}`,
      icon: <i className="fas fa-people-carry"></i>,
      bnb: "bnb2",
      url: '/venue-manager'
    },
    {
      today: `My Events`,
      title: `${dashboard && dashboard.totalEvents ? dashboard.totalEvents : 0}`,
      icon: <i className="fas fa-user-tie"></i>,
      bnb: "bnb2",
      url: '/event'
    }
  ];


  useEffect(() => {
    getEvents()
    setLoading(true)
    getDashboard()
  }, [])

  const getDashboard=(value) => {
    request({
      url: `${apiPath.dashboard}?event_id=${value?value: ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setDashboard(data.data);
       
      },

      onError: (error) => {
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const columns = [
    {
      title: `${lang("Event Image")}`,
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => {
        return <Image className="image-index-radius" src={apiPath.assetURL + image} />
      },
    },
    {
      title: `${lang("Event Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id }) => {
        return (<a className="cap avatar-text" >{name ? name : 'Not Available'}</a>);
      },
      sorter: (a, b) => {
        let nameA = a.name?.toLowerCase();
        let nameB = b.name?.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: `${lang("Event Description")}`,
      dataIndex: "description",
      key: "description",
      render: (_, { description, _id }) => {
        return (<a className="cap avatar-text" >{description ? description : 'Not Available'}</a>);
      },
    },
    {
      title: `${lang("Venue Name")}`,
      dataIndex: "venue_id",
      key: "venue_id",
      render: (_, { venueDetails }) => {
        return (<a className="cap avatar-text">{venueDetails ? venueDetails?.name : 'Not Available'}</a>);
      },
      sorter: (a, b) => {
        let nameA = a.venue_id?.name?.toLowerCase();
        let nameB = b.venue_id?.name?.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
     {
      title: `Event Type`,
      dataIndex: "event_type",
      key: "event_type",
      render: (_, { event_type, _id }) => {
        return (<a className="cap avatar-text" >{event_type}</a>);
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.created_at;
        let nameB = b.created_at;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={"View Event"} color={"purple"} key={"view" + "event"}>
              <Button title="View" onClick={() =>     navigate(`/event/view/${record._id}`)
}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="layout-content">
        <div className="mb-24">
          <Row  gutter={[24, 0]}>
            <Col xs={24} sm={24} md={8}>
              <Row className="rowgap-vbox" gutter={[24, 16]} style={{ marginLeft: "0" }}>
                {count.map((c, index) => (
                  <Col key={index} xs={24} sm={24} md={12} lg={12} className="mb24" style={{ paddingRight: "0px" }}>
                    <Link to={c.url}>
                      <Card bordered={false} className="criclebox" style={{ height: "100%" }}>
                        <div className="number"> 
                          <div className="icon-box dashbord-icons">{c.icon}</div> 
                            <div className="box-details">
                              <span>{c.today}</span>
                              <Title level={3}>{c.title}</Title>
                            </div>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={1}></Col>
            <Col xs={24} sm={24} md={15}>
              <Row className="rowgap-vbox" gutter={[24]} style={{ marginLeft: "0" }}>

                <Col span={24} lg={24} sm={24}>
                  <Form.Item className="mb-1"
                    label="Event Name"
                    name="event_id"
                    normalize={value => value.trimStart()}
                    rules={[
                      { required: true, message: "Please select event!" },
                    ]}
                  >
                    <Select
                      style={{
                        width: '100%',
                      }}
                      showSearch  // Enable search functionality
                      filterOption={(inputValue, option) =>
                        option.label.toLowerCase().includes(inputValue.toLowerCase())
                      }
                      placeholder="Select Event"
                      onChange={(value) => getDashboard(value)}
                      options={events?.map(v => ({
                        label: v?.name,
                        value: v?._id
                      }))}
                    />
                  </Form.Item>

                </Col>
                <Col span={24}>
                  <div className="header-gusts">
                  <p className=""> Admin Commission </p>
                  <Button>Pay Now</Button>
                  </div>
                
                  <div className="gust-main-c">
                  <div className="gust-card">
                  <p>Guestlist: </p>
                  <span>${dashboard.guestCommission ? dashboard.guestCommission : 0}</span>
                  </div>
                  <div className="gust-card"> 
                  <p>Table:</p>
                  <span>${dashboard.tableCommission ? dashboard.tableCommission : 0}</span>
                  </div>
                  <div className="gust-card">
                  <p>Tickets: </p>
                  <span>${dashboard.ticketCommission ? dashboard.ticketCommission : 0}</span>
                  </div>
                  </div>
               
                </Col>
               

              </Row>
            </Col>
          </Row>
        </div>

        {/* <div className="mb-24">
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24}>
              <Row className="rowgap-vbox" gutter={[24, 16]} style={{ marginLeft: "0" }}>
              
          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Event Name"
              name="event_id"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select event!" },
              ]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                showSearch  // Enable search functionality
                filterOption={(inputValue, option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                placeholder="Select Event"
                onChange={(value) => getDashboard(value)}
                options={events.map(v => ({
                  label: v?.name,
                  value: v?._id
                }))}
              />
            </Form.Item>

          </Col>
          <Col>
                <p> Admin Commission </p>
                <p>Guestlist: <span>${dashboard.guestCommission? dashboard.guestCommission: 0}</span></p>
                <p>Table: <span>${dashboard.tableCommission? dashboard.tableCommission: 0}</span></p>
                <p>Tickets: <span>${dashboard.ticketCommission? dashboard.ticketCommission: 0}</span></p>
          </Col>
          <Button>Pay Now</Button>

              </Row>
            </Col>
          </Row>
        </div> */}

      
        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Card bordered={false} className="criclebox tablespace" style={{ marginBottom: '16px' }}>
              <p className="dashboard-table-heading">{"Ongoing Events"}</p>
              <div className="table-responsive">
              <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        
         
        </Row>
      </div>
    </>
  );
}

export default Home;
